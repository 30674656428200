<template>
  <div @mousemove="mousemove">
    <div class="info">
      <div class="vm-search">
        <vm-search label="楼盘总数" :title-style="titleStyle">
          <div class="contentStyle">
            {{cityDetail.realEstateQuantity}}
          </div>
        </vm-search>
        <vm-search label="在保电梯总数" :title-style="titleStyle">
          <div class="contentStyle">
            {{cityDetail.elevatorQuantity}}
          </div>
        </vm-search>
        <vm-search label="班组总数" :title-style="titleStyle">
          <div class="contentStyle">
            {{cityDetail.teamQuantity}}
          </div>
        </vm-search>

        <vm-search label="选择城市：">
          <vm-district-select
            :province-code.sync="filter.provinceCode"
            :city-code.sync="filter.cityCode"
            :area-code.sync="filter.districtCode"
            :direct-name.sync="filter.directName">
          </vm-district-select>
        </vm-search>
        <el-button type="primary" style="margin-left: 4px" @click="getCityDetail">确定</el-button>
      </div>
    </div>
    <div :style="{width: '323px',background: '#ffffff',boxShadow: '10px 10px 9px 10px rgba(67,101,133,0.12)',display:display} " class="tree">
      <el-select
        v-model="filter.name"
        filterable
        style="border: none"
        size="large"
        remote
        reserve-keyword
        placeholder="请输入关键词"
        :remote-method="handleChange"
        :loading="loading"
        clearable
        @change="handleSelect">
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name"
          :value="item.name">
        </el-option>
      </el-select>
      <ul style="min-height: 582px;padding-left: 20px">
        <li
          v-for="(item,index) in list"
          :key="index"
          :class="selectRealEsatateId===item.id?'content-active':''"
          class="estatelist"
          :style="{color:getColor(item)}"
          @click="handleNodeClick(item)">
          <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
            <span style="display: inline-block;width: 150px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap">{{item.name}}</span>
          </el-tooltip>

          <!--            <el-tag v-for="(v,i) in getColor(item).reverse()" :key="i" :type="v.type" style="float: right;margin: 8px 2px 0 2px;">{{v.name}}</el-tag>-->
        </li>
      </ul>
      <el-pagination
        background
        small
        layout="prev, pager, next"
        :total="count"
        :page-size="15"
        style="width: 80px;margin-top: 24px"
        :hide-on-single-page="true"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <div v-show="show" :class="draw" :style="{display:show?display:show}">
      <div>
        <div
          style="height: 40px;color: #FFFFFF;text-align: center;width: 100%;display: flex;justify-content: flex-start"
        >
          <span
            style="height: 40px;font-size: 24px;background-color: #0C54C1;line-height: 40px;text-align: center;width: 40px; display: inline-block"
            @click="()=>{
              show = false}">
            &lt;
          </span>
          <div style="color: #1a1a1a;text-align: center;font-size: 20px;font-weight: bold;line-height: 40px">
            {{realEstateName}}
          </div>
        </div>
      </div>
      <div class="header">
        <div class="title">
          <!--            {{realEstateName}}-->
          <span
            style="width: 140px;height: 19px;font-size: 14px;font-family: PingFangSC-Regular;text-align: left;color: rgba(0,0,0,0.85);line-height: 22px;">维保班组：{{realEstateDetail.teamName}}</span>
          <span>|</span>
          <span>签到半径：{{realEstateDetail.signRadius}}米</span>
        </div>

        <el-button
          v-if="drawStatus==='收起'"
          class="drawButton"
          type="primary"
          plain
          @click="open">
          {{drawStatus}}
        </el-button>
      </div>
      <div class="contentBox">
        <div>
          <ul class="infinite-list">
            <li
              v-for="item in elevatorList"
              :key="item.id"
              :class="selectId===item.id?'content content-active':'content'"
              @click="gotoMonitor(item.id)"
            >
              <div class="zhanshi">
                <div
                  :class="selectId===item.id?'area area-active':'area'"
                  style="color: rgba(0,0,0,0.85);
                    margin-bottom: 21px;
">
                  {{item.buildingName}}
                </div>
              </div>
              <el-tag v-for="(v,i) in getColor(item).reverse()" :key="i" :type="v.type" style="float: right;margin: 8px 2px 0 2px;">{{v.name}}</el-tag>

              <div :class="selectId===item.id?'el-icon el-icon-active':'el-icon'">
                <i class="el-icon-arrow-right"></i>
              </div>
            </li>
          </ul>
          <el-pagination
            style="margin-top: 12px"
            small
            background
            layout="prev, pager, next"
            :total="elevatorPageCount"
            :page-size="15"
            @current-change="handleEelevatorPageChange"
          >
          </el-pagination>
        </div>

        <div v-if="drawStatus==='收起'" style="width: 250px;text-align: center">
          <div v-if="connectTip" class="car-tip">
            <img class="car-loading" src="~@/assets/img/monitor/loading.gif"/>
            <span>{{connectTip}}</span>
          </div>
          <table class="vm-table data-point" style="margin: 0 auto">
            <tr>
              <th colspan="2">数据点</th>
            </tr>
            <tr>
              <td style="width: 100px">服务模式</td>
              <td style="width: 100px">{{getDict("mode")}}</td>
            </tr>
            <tr>
              <td>运行状态</td>
              <td>{{getDict("run")}}</td>
            </tr>

            <tr>
              <td>运行方向</td>
              <td>{{getDict("dir")}}</td>
            </tr>
            <tr>
              <td>速度(m/s)</td>
              <td>{{g01.speed}}</td>
            </tr>
            <tr>
              <td>门状态</td>
              <td>{{getDict("door")}}</td>
            </tr>
            <tr>
              <td>是否有人</td>
              <td>{{getPerson(g01.person)}}</td>
            </tr>
            <tr>
              <td>累计运行时间(分钟)</td>
              <td>{{g04.runTime}}</td>
            </tr>
            <tr>
              <td>累计运行次数</td>
              <td>{{g04.runCount}}</td>
            </tr>
            <tr>
              <td>钢丝绳折弯次数</td>
              <td>{{g04.ropeBendCount}}</td>
            </tr>
            <tr>
              <td>电梯开门次数</td>
              <td>{{g04.doorOpenCount}}</td>
            </tr>
            <tr>
              <td>满载</td>
              <td>{{g01.LoadPercentage?'满载':'未满载'}}</td>
            </tr>
            <tr>
              <td>超载</td>
              <td>{{g01.fullLoad?'超载':'未超载'}}</td>
            </tr>
            <tr>
              <td>故障码</td>
              <td>{{g01.faults}}</td>
            </tr>
            <tr>
              <td>数据时间</td>
              <td>{{g01.time}}</td>
            </tr>
            <tr>
              <td colspan="2">网络延迟<span>{{g01.delay}}ms</span></td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <div id="map"></div>
    </div>
  </div>
</template>
<script>
  import MapLoader from "@/util/map";
  import Mqtt from "@/util/mqtt";
  import {mutations} from "@/store/store";



  export default {
    components: {},
    data() {
      return {
        map: null,
        wbOverdueCount: 0,
        exportWbLoading: false,
        AMap:null,
        show:false,
        draw:"draw",
        drawStatus:"展开",
        title:"",
        data:[],
        list:[],
        selectId:0,
        selectRealEsatateId:0,
        elevatorList:[],
        satelliteLayer:null,//卫星图层
        detail:{},
        districtCode:"",
        customerCompanyId:"",
        directName:"",
        display:"none",
        makerInfo:{
          name:"电梯1",
          content:"内容1",
          img:"http://tpc.googlesyndication.com/simgad/5843493769827749134",
          person:"周宇",
          phone:123456,
          area:"威海市经济技术开发区九龙城智慧谷",
          path:"#/elevator/Elevatordetail?elevatorId=",
          id:58,
        },
        clusterIndexSet: {
          city: {
            minZoom: 2,
            maxZoom: 10,
          },
          district: {
            minZoom: 10,
            maxZoom: 12,
          },
          area: {
            minZoom: 12,
            maxZoom: 15,
          },
          community: {
            minZoom: 15,
            maxZoom: 22,
          },

        },
        realEstateName:"",
        options:[],
        value:"",
        times:null,
        loading:false,
        filter:{

        },
        count:0,
        realEstateDetail:{
        },
        elevatorCount:0,
        elevatorPageCount:0,
        g01: {},
        g04: {},
        elevator:{},
        monitorDicts:{},
        connectTip: "连接中...",
        cityDetail:{},
        zoom:14,
        titleStyle:"font-size: 20px;color: rgba(0,0,0,0.85)",
      };
    },
    mounted() {
      this.getCityDetail();
    },
    destroyed() {
      if (this.mqttClient) {
        this.mqttClient.end();
      }
    },
    methods: {
      mousemove(e) {
        if(e.x<20&&e.y>70) {
          this.display = "block";
        }else if (e.x>550) {
          this.display = "none";
        }

      },

      gotoMonitor(elevatorId) {

        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitordetail",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");



      },
      getColor(item) {
        if(!item.isMaintainOrder&&!item.isRepairOrder) {
          return [{name:"正常",type:"success"}];
        }else if (item.isMaintainOrder&&!item.isRepairOrder) {
          return [{name:"保养中",type: "warning"}];
        }else if (!item.isMaintainOrder&&item.isRepairOrder) {
          return [{name:"急修中",type:"danger"}];
        }else {
          return [{name:"急修中",type:"danger"},{name:"保养中",type:"warning"}];
        }
      },
      getCityDetail() {
        this.getTreeList();
        this.initMap();
        this.$http.get("/work-map/district-statistics",{districtCode:this.filter.districtCode?this.filter.districtCode:this.filter.cityCode}).then(res=>{
          this.cityDetail = res;
        });
      },
      getElevator(elevatorId) {
        this.elevator.id = elevatorId;
        this.$http.get(`elevator-archive/${elevatorId}`).then(data => {
          this.elevator = data;
          this.floors=data.floors instanceof Array ? data.floors:[];
          if (this.elevator.elevatorIot) {
            if(!this.elevator.elevatorIot.online) {
              this.connectTip = "该电梯暂未在线";

            }
            this.initMqtt(this.elevator.elevatorIot.iotCode);
            this.startMonitor();
          } else {
            this.connectTip = "该电梯不支持远程监测";
          }
        });
      },
      initMqtt(deviceNo) {
        let that = this;
        this.locked=false;
        let mqtt = new Mqtt();
        // let arr =[];
        // let tempArr=[];
        let g01Topic = `giot/${deviceNo}/gateway/g01`;//g01电梯状态数据
        let g04Topic = `giot/${deviceNo}/gateway/g04`;
        mqtt.connect({
          onConnect(client) {
            that.mqttClient = client;
            client.subscribe([g01Topic,g04Topic]);
            that.connectTip = "等待数据";
          },
          onMessage(topic, message) {
            that.connectTip = "";
            console.log(topic,299);
            if (topic === g01Topic) {
              const time = new Date() - new Date(message.time);

              message.delay = time;
              that.floors.forEach(item=>{

                if (item.name===message.floor) {

                  message.floor=item.displayName;
                }
              });

              that.g01 = message;

            } else if (topic === g04Topic) {

              const time = new Date() - new Date(message.time);
              message.delay = time;

              that.g04 = message;
            }
          },
          onError(err) {
            that.connectTip = err;
            console.log(err);
          },
        });
      },
      getDict(name) {
        return this.$oc(() => this.monitorDicts[name][this.g01[name]]);
      },
      getPerson(person) {
        if(person>0) {
          return person;
        }else if(person==0) {
          return "无人";
        }else if(person == -2) {
          return "有人但人数未知";
        }else {
          return "未知";
        }
      },
      startMonitor() {
        this.$http.get(`elevator-iot/monitor/${this.elevator.elevatorIot.iotCode}/start`).then(() => {
          console.log("startMonitor成功");
          // clearInterval(this.heartInterval);
          // this.heartInterval = setInterval(() => {
          //   this.heartbeat();
          // }, 120000);
        });
      },
      heartbeat() {
        this.$http.get(`elevator-iot/monitor/${this.elevator.elevatorIot.iotCode}/heartbeat`).then(() => {
          console.log("心跳请求成功");
        });
      },

      handlePageChange(e) {
        this.getTreeList({pageNo:e});
      },
      handleEelevatorPageChange(e) {
        this.getEleList({pageNo:e});
      },
      handleSelect() {

        this.initMap();
        this.getTreeList();
      },
      handleRelateSelect(e) {
        console.log(e,456);
        this.getEleList();
      },
      remoteMethod(query) {
        if (query !== "") {
          this.loading = true;
          setTimeout(async() => {
            this.loading = false;
            const list = await this.$http.get("/real-estate/list",{name:query});
            this.options = list;
          }, 200);
        } else {
          this.options = [];
        }
      },
      handleChange(query) {
        if(this.times) {
          clearTimeout(this.times);
        }
        this.times = setTimeout(() => {
          //调用接口
          this.remoteMethod(query);
        }, 500);
      },
      beforeDestroy() {
        clearTimeout(this.times);
      },
      /**
       * 初始化地图
       */
      initMap() {
        // if (this.map) this.map.clearMap();

        MapLoader().then(AMap => {
          this.AMap = AMap;
          let mapOption = {};
          console.log(this.lng,this.lat,486,this.AMap);
          if (this.lng && this.lat) {
            console.log(488);
            mapOption.center = [this.lng, this.lat];
            mapOption.zoom = 16;

            this.map.setCenter([this.lng, this.lat]);
            this.map.setZoom(16);
          } else {
            var opts = {
              subdistrict: 0,   //获取边界不需要返回下级行政区
              extensions: "all",  //返回行政区边界坐标组等具体信息
              level: "district",  //查询行政级别为 市
            };
            const DistrictSearch = new AMap.DistrictSearch(opts);


            DistrictSearch.search(this.filter.directName?this.filter.directName:"威海",(status, result)=> {
              console.log(this.filter.directName,result.info,485);
              if (result.info.toLowerCase()==="ok") {
                if (result.districtList instanceof Array && result.districtList.length>0) {
                  mapOption.center = [result.districtList[0].center.lng,result.districtList[0].center.lat];
                  mapOption.zoom = 14;
                  this.map = new AMap.Map("map", mapOption);
                  this.map.addControl(new AMap.Geolocation({
                    position:{bottom:"100px",right:"20px"},
                  })); // 定位
                  this.map.addControl(new AMap.Scale());//比例
                  this.map.addControl(new AMap.ToolBar());//工具箱
                  const mapZoom=()=> {
                    var zoom = this.map.getZoom(); //获取当前地图级别
                    this.zoom =zoom;
                  };
                  // 事件绑定
                  const zoomOn=()=> {
                    this.map.on("zoomchange", mapZoom);
                  };
                  zoomOn();

                  this.getCurse();
                }else {
                  mapOption.center = [122.173639,37.421945];
                  mapOption.zoom = 14;
                  this.map = new AMap.Map("map", mapOption);
                  this.map.addControl(new AMap.Geolocation({
                    position:{bottom:"100px",right:"20px"},
                  })); // 定位
                  this.map.addControl(new AMap.Scale());//比例
                  this.map.addControl(new AMap.ToolBar());//工具箱
                  const mapZoom=()=> {
                    var zoom = this.map.getZoom(); //获取当前地图级别
                    this.zoom =zoom;
                  };
                  // 事件绑定
                  const zoomOn=()=> {
                    this.map.on("zoomchange", mapZoom);
                  };
                  zoomOn();
                }
                this.getCurse();
              }else {
                mapOption.center = [122.173639,37.421945];
                mapOption.zoom = 14;
                this.map = new AMap.Map("map", mapOption);
                this.map.addControl(new AMap.Geolocation({
                  position:{bottom:"100px",right:"20px"},
                })); // 定位
                this.map.addControl(new AMap.Scale());//比例
                this.map.addControl(new AMap.ToolBar());//工具箱

                const mapZoom=()=> {
                  var zoom = this.map.getZoom(); //获取当前地图级别
                  this.zoom =zoom;
                };
                // 事件绑定
                const zoomOn=()=> {
                  this.map.on("zoomchange", mapZoom);
                };
                zoomOn();
                this.getCurse();
              }
              // const route =[[
              //                 122.085711,
              //                 37.504543,
              //               ],
              //               [
              //                 122.0977,
              //                 37.501825,
              //               ],
              //               [
              //                 122.098167,
              //                 37.510101,
              //               ],
              //               [
              //                 122.100814,
              //                 37.519857,
              //               ],
              //               [
              //                 122.106574,
              //                 37.515041,
              //               ],
              //               [
              //                 122.110311,
              //                 37.519857,
              //               ],
              //               [
              //                 122.093029,
              //                 37.526649,
              //               ],
              //               [
              //                 122.091004,
              //                 37.524056,
              //               ],
              //               [
              //                 122.083375,
              //                 37.525661,
              //               ],
              //               [
              //                 122.081662,
              //                 37.521833,
              //               ],
              //               [
              //                 122.078081,
              //                 37.516893,
              //               ],
              //               [
              //                 122.069375,
              //                 37.512447,
              //               ],
              //               [
              //                 122.069063,
              //                 37.503308,
              //               ],
              //               [
              //                 122.085723,
              //                 37.504666,
              //               ]];
              // const list = [];
              // route.forEach(item=>{
              //   list.push(new AMap.LngLat(item[0],item[1]));
              // });
              // // var path = [
              // //
              // //   new AMap.LngLat(122.085711,
              // //                   37.504543),
              // //   new AMap.LngLat(121.92055,
              // //                   37.480136),
              // //   new AMap.LngLat(121.856176,
              // //                   37.377897),
              // //   new AMap.LngLat(121.847458,
              // //                   37.369645),
              // //   new AMap.LngLat(122.254555,
              // //                   37.481333),
              // //   new AMap.LngLat(122.132144,
              // //                   37.568707),
              // // ];
              //
              // var polygon = new AMap.Polygon({
              //   path: list,
              //   fillColor: "#fff", // 多边形填充颜色
              //   borderWeight: 2, // 线条宽度，默认为 1
              //   strokeColor: "red", // 线条颜色
              // });
              //
              // this.map.add(polygon);
              console.log(status,result,482);
            });
          }
        });
      },
      /**
       * 聚合
       */
      getCurse() {
        console.log(301,this.filter);
        const arr= [];
        this.$http.get("work-map/real-estate",{...this.filter,districtCode:this.filter.districtCode?this.filter.districtCode:this.filter.cityCode}).then(res => {
          this.elevatorCount = res.length;
          res.forEach(item=>{
            console.log(item,582);
            arr.push({isEffective:item.peopleTrappedQuantity,isMaintainOrder:item.maintainQuantity,isRepairOrder:item.repairQuantity,elevatorQuantity:item.elevatorQuantity,id:item.id,name:item.name,"lnglat":[item.longitude,item.latitude]});
          });
          var cluster = new this.AMap.MarkerClusterer(this.map, arr, {
            gridSize: 80, // 聚合网格像素大小
            minClusterSize:5,
            maxZoom:13,
            renderClusterMarker: this._renderClusterMarker, // 自定义聚合点样式
            renderMarker: this._renderMarker,   // 自定义非聚合点样式
          });

          cluster.on("click", e => {
            if (e.clusterData.length<=1) {
              console.log(e.cluster.m[0]);
              this.show = true;
              this.makerInfo = e.cluster.m[0];
              this.openInfoWindow(e.lnglat,e.cluster.m[0].id);
            }

            // this.makerInfo = item;

          });
          console.log(cluster,324);
          this.map.add(cluster);
        });
        this.data = arr;

      },
      getPoints() {
        console.log(329);
        this.map.clearMap();
        this.$http.get("work-map/real-estate?districtCode=" + this.districtCode + "&customerCompanyId=" + this.customerCompanyId).then(data => {
          let points = data;
          this.data = data;
          console.log(data,200);
          this.elevatorCount = points.length;
          let markers = [];
          // let cluster = [];
          // let arr = [];
          points.forEach(item => {
            if (item.longitude && item.latitude) {
              let marker = new this.AMap.Marker({
                position:new this.AMap.LngLat(item.longitude, item.latitude),
                icon: "https://s1.ax1x.com/2020/11/06/BWzNyF.png",
                offset: new this.AMap.Pixel(-12, -33),
                lnglat:[item.longitude,item.latitude],
              });
              marker.on("click", e => {
                this.makerInfo = item;
                this.show = true;
                this.openInfoWindow(e.target.getPosition(), item.id,"click");
              });

              // arr.push({weight:1,name:item.name,"lnglat":[item.longitude,item.latitude]});

              markers.push(marker);
            }
          });
          this.map.add(markers);
        });
      },
      openInfoWindow(position, elevatorId,type) {
        let routeUrl = `#/monitor/liftMonitor?elevatorId=${elevatorId}`;
        console.log(643,type);
        if (!type||type==="click") {
          this.map.setZoomAndCenter(16, position);

        }
        // this.title = this.makerInfo.name;
        this.realEstateName = this.makerInfo.name;
        this.getEleList();
        this.info(position, routeUrl);
      },
      /**
       * 电梯列表
       * @param params
       */
      getEleList(params) {
        this.$http.get("/work-map/archive/page", {realEstateName:this.realEstateName,...params,pageSize:15}).then(res => {
          console.log(352,res.total);
          this.elevatorPageCount = res.total;
          this.elevatorList = res.records;
        });
      },
      /**
       * 三级菜单开启
       */
      open() {
        this.filter={};
        if (this.drawStatus === "展开") {
          this.draw = "draw-open";
          this.drawStatus = "收起";
        } else {
          this.draw = "draw-close";
          this.drawStatus = "展开";
        }
      },
      /**
       * 电梯选择
       * @param item
       */
      onSelect(item = {}) {
        mutations.getMonitorDicts().then(result => {
          this.monitorDicts = result;
        });
        if (this.mqttClient) {
          this.mqttClient.end();
        }
        this.g01={};
        this.g04={};
        this.monitorDicts={};
        this.selectId = item.id;
        this.detail = item;
        this.draw = "draw-open";
        this.drawStatus = "收起";
        this.getElevator(item.id);
      },
      initDraw() {
        // this.data.forEach({})
      },
      /**
       * 消息框的实现
       * @param title
       * @param content
       * @returns {HTMLDivElement}
       */
      createInfoWindow(title, content) {
        var info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";

        //可以通过下面的方式修改自定义窗体的宽高
        info.style.width = "400px";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top";
        titleD.innerHTML = title;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        closeX.onclick = this.closeInfoWindow;

        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);

        // 定义中部内容
        var middle = document.createElement("div");
        middle.className = "info-middle";
        middle.style.backgroundColor = "white";
        middle.innerHTML = content;
        info.appendChild(middle);

        // 定义底部内容
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = "relative";
        bottom.style.top = "0px";
        bottom.style.margin = "0 auto";
        var sharp = document.createElement("img");
        sharp.src = "https://webapi.amap.com/images/sharp.png";
        bottom.appendChild(sharp);
        info.appendChild(bottom);
        return info;
      },
      createInfoWindowSingle(title, content) {
        var info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";
        // info.style.borderRadius="50%";
        //可以通过下面的方式修改自定义窗体的宽高
        info.style.width = "220px";
        // info.style.zIndex=1;
        info.style.border= "none";
        // info.style.border="1px solid black";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top-single";
        titleD.innerHTML = title +"<div style=\"width: 8px;height: 8px;padding:0;margin: 0 4px;background: #0c54c1;\"></div>"+ content;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        closeX.onclick = this.closeInfoWindow;

        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);

        return info;
      },
      /**
       * 消息框
       * @param marker
       */
      info(marker) {
        // let img = <img src="this.makerInfo.img"/>
        var title =  this.makerInfo.name,
            content = [];
        content.push(this.makerInfo.elevatorQuantity + "台");
        // content.push("客户公司：" + this.makerInfo.customerCompanyName);
        // content.push("负责人：" + this.makerInfo.assigneeName);

        // content.push("<a style='margin-left: 3px;border: none' target='_blank' href="+url+">监控</a><a style='border: none' target='_blank' href="+this.makerInfo.path+this.makerInfo.id+">详细信息</a>");

        var infoWindow = new this.AMap.InfoWindow({
          isCustom: true,  //使用自定义窗体
          content: this.createInfoWindowSingle(title, content.join("<br/>")),
          offset: new this.AMap.Pixel(125, 20),
          autoMove: true,
        });
        this.infoWindowPoint  = infoWindow;
        // 打开信息窗体
        infoWindow.open(this.map, marker);
      },
      infoList(marker,arr) {
        let list=[];
        arr.forEach(item=>{
          console.log(item._amapMarker.originData[0],543);
          list = list.concat(item._amapMarker.originData[0]);
        });
        var title = "共" + list.length+"个楼盘(只保留台量前10的楼盘)",
            content = [];

        list.sort(function(a,b) {
          return b.elevatorQuantity- a.elevatorQuantity;
        }).slice(0,9).forEach(item=>{
          content.push(item.name+":"+ item.elevatorQuantity + "台");
        });

        var infoWindow = new this.AMap.InfoWindow({
          isCustom: true,  //使用自定义窗体
          content: this.createInfoWindow(title, content.join("<br/>")),
          offset: new this.AMap.Pixel(16, -45),
          autoMove: true,
        });
        this.infoListWindown = infoWindow;

        // 打开信息窗体
        infoWindow.open(this.map, marker);
      },
      closeInfoWindow() {
        this.show = false;
        this.map.clearInfoWindow();
      },
      _renderClusterMarker(context) {

        // 聚合中点个数
        // var clusterCount = context.count;
        var div = document.createElement("div");

        // let factor = Math.pow(context.count / this.elevatorCount, 1 / 18);
        // let Hue = 180 - factor * 180;
        // let bgColor = "hsla(" + Hue + ",100%,50%,0.7)";
        // let fontColor = "hsla(" + Hue + ",100%,20%,1)";
        // let borderColor = "hsla(" + Hue + ",100%,40%,1)";
        // let shadowColor = "hsla(" + Hue + ",100%,50%,1)";
        const icon = require("../../assets/map/组 165.png");
        div.style.background="url("+icon+") 0 0 no-repeat";
        // div.style.backgroundRepeat="no-repeat";
        // div.style.backgroundColor = bgColor;
        // div.style.backgroundColor = "rgba(" + bgColor + ",.5)";
        // var size = Math.round(25 + Math.pow(clusterCount / this.data.length, 1 / 10) * 40);
        div.style.width =  32 + "px";
        div.style.height = 30 +"px";
        // div.style.border = "solid 1px rgba(" + bgColor + ",1)";
        // div.style.borderRadius = size / 2 + "px";
        // div.innerHTML = context.count;
        div.style.lineHeight = 30 + "px";
        div.style.color = "#ffffff";
        // div.style.fontSize = "12px";
        // div.style.textAlign = "center";
        // // div.style.width = div.style.height = size + "px";
        // div.style.border = "solid 1px " + borderColor;
        // // div.style.borderRadius = size / 2 + "px";
        // div.style.boxShadow = "0 0 1px " + shadowColor;
        // console.log(context,838);
        div.innerHTML = context.count;
        // div.style.lineHeight = size + "px";
        // div.style.color = fontColor;
        // div.style.fontSize = "14px";
        div.style.textAlign = "center";
        // context.marker.setOffset(new this.AMap.Pixel(-size / 2, -size / 2));
        context.marker.setContent(div);
        context.marker.on("mouseover",e=>{
          console.log(830);
          this.infoList(e.target.getPosition(),context.clusterData);

        });
        context.marker.on("mouseout",()=>{
          this.infoListWindown.close();

          // this.infoList(e.target.getPosition(),context.clusterData);

        });
        context.marker.on("click", (e) => {

          var curZoom = this.map.getZoom();
          if (curZoom < 20) {
            curZoom += 2;
          }
          this.map.setZoomAndCenter(curZoom, e.lnglat);
        });
      },
      _renderMarker(context) {

        const normal  = require("../../assets/map/组 146.png");
        const repair = require("../../assets/map/组 159.png");
        const main = require("../../assets/map/组 171.png");
        var div = document.createElement("div");
        console.log(900,context);
        if (context?.data[0]?.isRepairOrder) {
          div.style.background="url("+repair+") 0 0 no-repeat";

        }else if (context?.data[0]?.isMaintainOrder) {
          div.style.background="url("+main+") 0 0 no-repeat";
        }else {
          div.style.background="url("+normal+") 0 0 no-repeat";

        }
        div.style.backgroundSize = "100% 100%";
        // div.style.backgroundRepeat="no-repeat";
        // div.style.backgroundColor = bgColor;
        // div.style.backgroundColor = "rgba(" + bgColor + ",.5)";
        // var size = Math.round(25 + Math.pow(clusterCount / this.data.length, 1 / 10) * 40);
        div.style.width =  28 + "px";
        div.style.height = 30 +"px";
        div.style.zIndex= 9999;
        console.log(context,880);
        // var content = "<div style=\"background: 'url('+normal+')';height: 18px; width: 18px; border: 1px solid rgba(255,255,178,1); border-radius: 12px; box-shadow: rgba(0, 0, 0, 1) 0px 0px 3px;\"></div>";
        var offset = new this.AMap.Pixel(-9, -9);
        context.marker.setContent(div);
        context.marker.setOffset(offset);

        context.marker.on("mouseover",e=>{
          console.log(context);
          // this.show = false;
          this.makerInfo = context.data[0];
          console.log(context,e);
          this.openInfoWindow(e.target.getPosition(),context.data[0],"over");

        });
        context.marker.on("mouseout",()=>{
          // this.infoListWindown.close();
          this.infoWindowPoint.close();
          // this.infoList(e.target.getPosition(),context.clusterData);

        });
      },
      getTreeList(params) {
        console.log(this.filter,630);
        let filter = this.filter;
        let http = this.$http.get("/work-map/real-estate/page",{...filter,...params,districtCode:this.filter.districtCode?this.filter.districtCode:this.filter.cityCode,pageSize:15});
        http.then(data => {
          this.list = data.records;
          this.count = data.total;
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        // this.filter.name = e.label;
        this.realEstateDetail= e;
        this.selectRealEsatateId = e.id;
        this.realEstateName=e.name;
        this.show = true;
        this.lat= e.latitude;
        this.lng= e.longitude;
        this.getEleList();
        this.initMap();
      },
      renderContent(h, {node}) {
        if (node.childNodes instanceof Array && node.childNodes.length>0) {
          return (<span>{node.label}</span>);
        }
        return (
            <span class="custom-tree-node">
              <el-tooltip  className="item" effect="dark" content={node.label} placement="top-start">
              <span>{node.label}</span>
              </el-tooltip>
          </span>);

      },
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
    },
  };
</script>
<style lang="scss" scoped>
  #map {
    height: calc(100vh - 70px);
    width: 100%;
  }
  .info {
    border: 1px solid #EBEEF5;
    margin-bottom: 10px;
    background-color: #f4f7fa;
    padding: 8px 15px;

    .item {
      font-size: 12px;
      display: inline-block;
      margin-right: 8px;
      background-color: #909399;
      color: white;
      line-height: 1;
      border-radius: 1px;
      padding: 7px 15px;
      border: 1px solid #909399;
    }
  }

  .tree{
    height: calc(100vh - 150px);
    width: 190px;
    background-color: white;
    position: absolute;
    left: 20px;
    top: 70px;
    z-index: 999;

    //border: 1px solid #E6E6E6;
  }
  .estatelist{
    height: 20px;
    size: 16px;
    margin-top: 21px;
    line-height: 20px;
    padding-left: 5px;
    cursor: pointer;
    color: rgba(0,0,0,0.85);
  }

  .draw{
    height: calc(100vh - 150px);
    width: 340px;
    z-index: 999;
    position: absolute;
    top: 70px;
    left: 210px;
    //left: 300px;
    border: 1px solid #E6E6E6;
    background: #ffffff;
    box-shadow: 10px 10px 9px 10px rgba(67,101,133,0.12);
  }


  .draw-open{
    height: calc(100vh - 150px);
    width: 870px;
    background-color: white;
    //position: absolute;
    z-index: 999;
    left: 300px;
    border: 1px solid #E6E6E6;
    animation-name: draw-open;
    animation-duration: 1s;
  }

  .draw-close{
    height: calc(100vh - 150px);
    width: 340px;
    background-color: white;
    //position: absolute;
    z-index: 999;
    //left: 300px;
    border: 1px solid #E6E6E6;
    animation-name: draw-close;
    animation-duration: 1s;
  }

  @keyframes draw-open {
    from{
      width: 340px;
    }
    to{
      width: 870px;
    }
  }
  @keyframes draw-close {
    from{
      width: 870px;
    }
    to{
      width: 340px;
    }
  }

  .header{
    height: 72px;
    box-sizing: border-box;
    width: 390px;
    padding: 10px 0 10px 0px;
    //border-bottom: 1px solid rgba(0,0,0,.1);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .drawButton{
     float: right;
   }

  .title{
    color: #1a1a1a;
    font-weight: 600;
    //border-left: 4px solid #3988ff;
    //width: calc(100% - 21px) ;
    //padding-left: 6px;
    //overflow: hidden;
    width: 323px;
    //margin: 0 auto;
    margin-left: 8px;
    height: 62px;
    line-height: 62px;
    text-align: center;
    background: #f2f5f8;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .infinite-list{
    min-height: 554px;
    overflow: scroll;
    display: inline-block;
    vertical-align: top;
    width: 320px;
    //padding: 15px 0px 0px 15px;
    position: relative;
    background-color: #fff;
    z-index: 20;
  }

  ul::-webkit-scrollbar{
    display:none;
  }

  .content{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;

  }

  .zhanshi{
    flex: 1;
    overflow: hidden;
    padding: 8px;
    width: 100%;
    height: 37px;
  }

  .content-active{
    //background-color:;
    border-right: 2px solid #3988ff ;
    //border-radius: 6px;
  }


  .name{
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }
  .area{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
  }

  .name-active{
    color: white;
  }
  .area-active{
    color: white;
  }

  .el-icon{
    font-family: element-icons!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
  }
  .el-icon-active{
    color: white;
  }
</style>
<style lang="scss">
  .amap-icon img {
    width: 24px;
    height: 35px;
  }
</style>
<style lang="scss">

.contentStyle{
  color: #000000;font-size: 20px;line-height: 22px;font-weight: bold;
}
.amap-sug-result {
  z-index: 3000 !important;
}
.contentBox{
  display: flex;
}
.amap-icon img {
  width: 24px;
  height: 35px;
}
.content-window-card {
  position: relative;
  box-shadow: none;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 0;
}

.content-window-card p {
  height: 2rem;
}

.custom-info {
  border: solid 1px silver;
}

div.info-top {
  position: relative;
  background: none repeat scroll 0 0 #F9F9F9;
  border-bottom: 1px solid #CCC;
  //border-radius:   0 50px  50px 0px;
  //background-color: #fff;
}
div.info-top-single {
  position: relative;
  //background: none repeat scroll 0 0 #F9F9F9;
  //border-bottom: 1px solid #CCC;
  border-radius:   0 50px  50px 0px;
  background-color: #fff;
}

div.info-top div {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 31px;
  padding: 0 10px;
}
div.info-top-single div {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 31px;
  padding: 0 10px;
}

div.info-top img {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-duration: 0.25s;
}div.info-top-single img {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-duration: 0.25s;
}

div.info-top img:hover {
  box-shadow: 0px 0px 5px #000;
}div.info-top-single img:hover {
  box-shadow: 0px 0px 5px #000;
}

div.info-middle {
  font-size: 12px;
  padding: 10px 6px;
  line-height: 20px;
}

div.info-bottom {
  height: 0px;
  width: 100%;
  clear: both;
  text-align: center;
}

div.info-bottom img {
  position: relative;
  z-index: 104;
}

//span {
//  margin-left: 5px;
//  font-size: 11px;
//}

.info-middle img {
  float: left;
  margin-right: 6px;
}
.car-tip {
  position: absolute;
  width: 200px;
  left: 530px;
  top: 330px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  .car-loading {
    width: 60px;
    height: 60px;
    background-color: rgba(164, 243, 145, 0.1);
    padding: 5px;
    border: 1px solid white;
    border-radius: 50%;
  }

  span {
    color: white;
    font-size: 25px;
    margin-left: 5px;
    text-shadow: 2px 2px 4px #000000;
  }
}
</style>
